@use 'custom-theme' as *

body, html
  height: 100%

body
  padding: 0
  margin: 0

*
  box-sizing: border-box

table
  width: 100%

  th
    font-weight: normal
  & tbody tr
    &.clickable
      cursor: pointer

      &:hover
        background-color: $backoffice-background

.button-icon
  cursor: pointer

.zig-logo
  width: 10rem

.dialog-actions-evenly
  justify-content: space-evenly


.second-line
  padding-top: 0.33em
  font-size: 0.8em
  line-height: 2em
