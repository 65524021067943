// TODO: New improvements to Angular v19 material theming that are worth investigating: https://material.angular.io/
// TODO: Update from V2 to V3 per the docs: https://material.angular.io/guide/material-2-theming#how-to-migrate-an-app-from-material-2-to-material-3
// Custom Theming for Angular Material
@use '@angular/material' as mat;

$material-design-icons-font-directory-path: '../node_modules/material-design-icons-iconfont/dist/fonts/';

$mdc-typography-font-family: Roboto, 'Helvetica Neue', sans-serif;

@use '../node_modules/material-design-icons-iconfont/dist/material-design-icons' as *;

// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$backoffice-primary: mat.m2-define-palette(mat.$m2-teal-palette);
$backoffice-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$backoffice-background: mat.m2-get-color-from-palette(mat.$m2-teal-palette, 100);

// The warn palette is optional (defaults to red).
$backoffice-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$backoffice-typography: mat.m2-define-typography-config();

$backoffice-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $backoffice-primary,
      accent: $backoffice-accent,
      warn: $backoffice-warn,
    ),
    typography: $backoffice-typography,
    density: 0,
  )
);

$backoffice-spacing: 0.66em;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($backoffice-theme);
@include mat.all-component-typographies($backoffice-typography);

body {
  margin: 0;
  --base-font: Roboto, 'Helvetica Neue';
  --mdc-dialog-supporting-text-font: var(--base-font);
  --mdc-dialog-supporting-text-line-height: 1.3;
  --mdc-dialog-supporting-text-weight: normal;
  --mdc-dialog-subhead-font: var(--base-font);
  --mdc-dialog-subhead-size: 21px;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-tracking: normal;

  --top-bottom-padding: 8px;
  --padding-large: 16px;
  --border-radius: 11px;
  --color-light: #ffffff;
  --color-grey-1: #efefef;
  --color-grey-2: #e7e7e7;
  --color-grey-3: #e1e1e1;
  --header-height: 56px;
  --drop-shadow: drop-shadow(1px 0 1px rgba(0, 0, 0, 0.5));
  --drop-shadow-down: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1));

  font-family: var(--base-font), sans-serif;
  font-size: var(--font-size-large);

  --font-size-small: 11px;
  --font-size-medium: 14px;
  --font-size-large: 16px;

  @media only screen and (max-width: 600px) {
    --header-height: 64px;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  hyphens: auto;
  word-break: break-all;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.mat-drawer-inner-container {
  padding-right: 10px;
}

.mat-drawer-container {
  h1 {
    padding-left: var(--padding-large);
    font-size: 24px;
  }
}

.mat-list-text {
  padding-right: 12px !important;
  padding-left: 4px !important;
}

.mat-sort-header-content {
  text-align: left !important;
}

.mat-mdc-table[_ngcontent-aoc-c79] {
  background: white;
}

table[_ngcontent-aoc-c79] {
  width: 100%;
}

*[_ngcontent-aoc-c79] {
  box-sizing: border-box;
}

.mat-mdc-nav-list,
.mat-mdc-paginator,
.mat-mdc-table {
  font-size: var(--font-size-medium, 14px);
}

.mat-mdc-dialog-surface {
  padding: var(--padding-large);
}

.table-overflow {
  max-width: 100%;
  overflow: auto;
}
